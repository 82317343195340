.App {
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}

input {
  display: block;
  width: 20rem;
  height: 2rem;
  padding: 0.5rem;
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 2rem;
}

.revshare {
  display:flex;
  flex-direction: row;
}

.revshare .address {
  display: block;
  width: 20rem;
  height: 2rem;
  padding: 0.5rem;
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 2rem;
}

.revshare .percentage {
  display: block;
  width: 5rem;
  height: 2rem;
  padding: 0.5rem;
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 2rem;
  margin-left: 1rem;
}

.nickname {
  margin-bottom: .5rem;
}

button {
  border: none;
  padding: 1rem;
  width: 21.2rem;
  font-size: 1.2em;
  border-radius: 0.2rem;
  cursor: pointer;
}

button:hover {
  background-color: #c5c5c5;
}

.message {
  font-size: 1.2em;
  color: #36a936;
}


/* Mobile view */
@media screen and (max-width: 600px) {
  .App {
    flex-direction: column;
  }
}